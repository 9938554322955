import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-vue-v3'

async function _getFingerprint(): Promise<string | null> {
  log.time('fingerprint')
  const { data, error, getData } = useVisitorData()
  const segment = useSegment()
  const anonId = await segment.$segment.user()

  if (detectFirefox()) {
    _fingerprint().value = String(anonId.anonymousId())

    return String(anonId.anonymousId())
  } else {
    if (data.value !== undefined) {
      return data.value.visitorId
    }
    const result = await getData()
    log.debug(`Fingerprint result: ${result}`)

    if (error.value !== undefined) {
      log.error('failed to get fingerprint', error.value)
      return null
    }

    if (result === undefined) {
      log.error('failed to get fingerprint, got undefined')
      return null
    }

    const { visitorId } = result
    if (visitorId === undefined || visitorId === null) {
      log.warn(`failed to get fingerprint, got response ${result}`)
    }

    log.timeEnd('fingerprint')

    _fingerprint().value = visitorId
    return visitorId
  }
}

const _fingerprint = () => useState<string | null>('fingerprint', () => null)

export default function () {
  return {
    fingerprint: _fingerprint(),
    getFingerprint: _getFingerprint
  }
}
